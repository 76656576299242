var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Iniciemos con tu registro 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Bienvenido a factura.green! ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"RFC","vid":"taxid","rules":"required|min:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"RFC (Registro Federal de Contribuyentes)","label-for":"register-taxid","invalid-feedback":_vm.t('taxidr')}},[_c('b-form-input',{attrs:{"id":"register-taxid","name":"register-taxid","formatter":_vm.fmtUppercase,"state":errors.length > 0 || !_vm.validateTaxID ? false:null,"placeholder":"XAXX010101AAA"},on:{"change":_vm.reviewTaxId},model:{value:(_vm.taxid),callback:function ($$v) {_vm.taxid=$$v},expression:"taxid"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.businesstype === 'pm' ?  'Razón Social' : 'Nombre',"vid":"businessname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.businesstype === 'pm' ?  'Razón Social' : 'Nombre',"label-for":"register-username","invalid-feedback":_vm.t('bnamereq')}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.t('tr_nombreorazon')),expression:"t('tr_nombreorazon')",modifiers:{"hover":true,"left":true}}],attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":_vm.businesstype === 'pm' ?  'ejem: LA POBLANITA' : 'ejem: MARCO LOPEZ LOERA',"formatter":_vm.fmtUppercaseAll},model:{value:(_vm.businessname),callback:function ($$v) {_vm.businessname=$$v},expression:"businessname"}})],1)]}}],null,true)}),(_vm.businesstype === 'pm')?_c('b-form-group',{attrs:{"label":"Regimen de Capital","label-for":"register-regime"}},[_c('validation-provider',{attrs:{"name":"Regimen de Capital","vid":"businessregime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.t('tr_regimen')),expression:"t('tr_regimen')",modifiers:{"hover":true,"left":true}}],attrs:{"id":"register-regime","name":"register-regime","state":errors.length > 0 ? false:null,"placeholder":"Regimen de Capital..ejemplo SA de CV, SRL de CV","formatter":_vm.fmtUppercaseAll},model:{value:(_vm.businessregime),callback:function ($$v) {_vm.businessregime=$$v},expression:"businessregime"}})]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Régimen Fiscal","label-for":"register-taxpayerid"}},[_c('validation-provider',{attrs:{"name":"TaxPayer Type","vid":"taxpayer","rules":"catalog"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"taxpayer","text-field":"v","label-field":"v"},model:{value:(_vm.taxpayertype),callback:function ($$v) {_vm.taxpayertype=$$v},expression:"taxpayertype"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("-- Selecciona un Regimen Fiscal --")]),_vm._l((_vm.taxpayerList.filter(function (c) { return c.pt.find(function (o) { return o === _vm.businesstype; }); })),function(option){return _c('option',{key:option.k,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.v)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Correo Electrónico","label-for":"register-email","invalid-feedback":_vm.t('emailr')}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.t('tr_mail')),expression:"t('tr_mail')",modifiers:{"hover":true,"left":true}}],attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"Correo Electrónico p.ej: usuario@dominio.com"},model:{value:(_vm.businessemail),callback:function ($$v) {_vm.businessemail=$$v},expression:"businessemail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password / Contraseña","vid":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"register-password","label":"Contraseña","invalid-feedback":_vm.t('tr_passerr')}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.t('tr_pass')),expression:"t('tr_pass')",modifiers:{"hover":true,"left":true}}],staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Check","rules":"required|isTrue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Estoy de acuerdo con "),_c('a',{attrs:{"href":"https://web.factura.green/termsandc.html","target":"_blank"}},[_vm._v("Términos de Uso y Política de Privacidad")]),_vm._v(". Recibirás un correo con las indicaciones para activar tu cuenta. ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.onRegisterBusy,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Registrar ")])],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("¿Tienes una cuenta?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Ingresar")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }