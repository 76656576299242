<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Iniciemos con tu registro 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Bienvenido a factura.green!
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- taxid -->
	        <validation-provider #default="{ errors }" name="RFC" vid="taxid" rules="required|min:12">
              <b-form-group label="RFC (Registro Federal de Contribuyentes)" label-for="register-taxid" :invalid-feedback="t('taxidr')">                
                  <b-form-input
                    id="register-taxid"
                    v-model="taxid"
                    name="register-taxid"
                    :formatter="fmtUppercase"
                    :state="errors.length > 0 || !validateTaxID ? false:null"
                    placeholder="XAXX010101AAA"
                    @change="reviewTaxId"
                  />
                  <!--<small class="text-danger">{{ errors[0] }}</small> ILL:020122 ALERTA RFC VACIÓ-->
              </b-form-group>
	        </validation-provider>

              <!-- businessname -->
          <validation-provider
            #default="{ errors }"
            :name="businesstype === 'pm' ?  'Razón Social' : 'Nombre'"
            vid="businessname"
            rules="required"
          >              
            <b-form-group
              :label="businesstype === 'pm' ?  'Razón Social' : 'Nombre'"
              label-for="register-username"
              :invalid-feedback="t('bnamereq')"
            >
            <b-form-input
              id="register-username"
              v-model="businessname"
              name="register-username"
              :state="errors.length > 0 ? false:null"
              :placeholder="businesstype === 'pm' ?  'ejem: LA POBLANITA' : 'ejem: MARCO LOPEZ LOERA'"
              :formatter="fmtUppercaseAll"
              v-b-tooltip.hover.left="t('tr_nombreorazon')"
            />            
          </b-form-group>
          </validation-provider>
        

              <b-form-group v-if="businesstype === 'pm'"
                label="Regimen de Capital"
                label-for="register-regime"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Regimen de Capital"
                  vid="businessregime"
                >
                  <b-form-input
                    id="register-regime"
                    v-model="businessregime"
                    name="register-regime"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Regimen de Capital..ejemplo SA de CV, SRL de CV"                    
                    :formatter="fmtUppercaseAll"
                    v-b-tooltip.hover.left="t('tr_regimen')"
                  />                  
                </validation-provider>
              </b-form-group>

              <!-- taxPayerId -->
              <b-form-group
                label="Régimen Fiscal"
                label-for="register-taxpayerid"
              >
                <validation-provider
                  #default="{ errors }"
                  name="TaxPayer Type"
                  vid="taxpayer"
                  rules="catalog"
                >
                  <!--<v-select
                    id="taxBandList"
                    v-model="taxpayertype"
                    text-field="v"
                    label="v"
                    :options="taxpayerList.filter(c => c.pt.find(o => o === businesstype))"
                  />-->
                  <b-form-select
                    id="taxpayer"
                    v-model="taxpayertype"
                    text-field="v"
                    label-field="v"
                  >
                    <option :value="null" disabled>-- Selecciona un Regimen Fiscal --</option>
                    <option v-for="option in taxpayerList.filter(c => c.pt.find(o => o === businesstype))" :key="option.k" :value="option">
                      {{ option.v }}
                    </option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
          <validation-provider
            #default="{ errors }"
            name="Email"
            vid="email"
            rules="required|email"
          >
              <b-form-group
                label="Correo Electrónico"
                label-for="register-email"
                :invalid-feedback="t('emailr')"
              >
                  <b-form-input
                    id="register-email"
                    v-model="businessemail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Correo Electrónico p.ej: usuario@dominio.com"
                    v-b-tooltip.hover.left="t('tr_mail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
          </validation-provider>

              <!-- password -->
              <validation-provider
                  #default="{ errors }"
                  name="Password / Contraseña"
                  vid="password"
                  rules="required|min:8"
                >              
                <b-form-group
                  label-for="register-password"
                  label="Contraseña"
                  :invalid-feedback="t('tr_passerr')"
                >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-password"
                        placeholder="············"
                        v-b-tooltip.hover.left="t('tr_pass')"

                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  
                </b-form-group>
            </validation-provider>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Check"
                  rules="required|isTrue"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Estoy de acuerdo con
                    <a href="https://web.factura.green/termsandc.html" target="_blank">Términos de Uso y Política de Privacidad</a>. Recibirás un correo con las indicaciones para activar tu cuenta.

                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>

              <b-overlay
                :show="onRegisterBusy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  Registrar
                </b-button>
              </b-overlay>

            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>¿Tienes una cuenta?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Ingresar</span>
            </b-link>
          </p>

          <!-- divider -->
          <!--<div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>-->
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BOverlay, BFormGroup, BFormInput, BFormSelect, VBTooltip, BInputGroup, BInputGroupAppend, BImg, BFormInvalidFeedback, BCardTitle, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, email, regex, catalog, alpha } from '@validations'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import taxpayerList from '@/@catalogs/sat/c_regimenfiscal'
import axios from '@axios'

export default {
  setup() {

    const { t } = useI18nUtils()

    return {
      t,
    }
  },
components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormSelect,
    BFormCheckbox,
    BOverlay,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    // validations
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
    directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      businessname: '',
      businessregime: '',
      businesstype: '',
      taxid: '',
      taxpayertype: {
        k: '',
        v: ''
      },
      businessemail: '',
      password: '',
      sideImg: require('@/assets/images/pages/fg.logo.svg'),
      // validation
      required,
      email,
      regex,
      catalog,
      alpha,
      taxpayerList,
      // partner
      partner: 'ark',
      // campaign uuid
      campaign_uuid:'',
      onRegisterBusy: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    validateTaxID(){
      const regEx = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/
      const isValidTaxID = regEx.test(this.taxid);
      return isValidTaxID || this.taxid ===''
    }
  },
  mounted() {
    if(this.$route.params.puuid) {
      this.partner = this.$route.params.puuid
    }
    if(this.$route.params.cuuid) {
      this.campaign_uuid = this.$route.params.cuuid
    }
  },
  methods: {
    reviewTaxId() {
      if(this.taxid && this.taxid.length === 12) {
        this.businesstype = 'pm'
      } else {
        this.businesstype = 'pf'
      }

      this.businessemail = '' 
      this.businessname = '' 
      this.businessregime = ''
      this.taxpayertype.k = ''
      this.taxpayertype.v = ''
      this.password = ''
      
    },
    // ILL: 140322 para que la longitud del campo nombre se transforme a mayusculas
    fmtUppercaseAll(value) {
        let v = value.toUpperCase()        
        return v
    },    
    checkFields() {
      if(this.businessemail !== '' && 
      this.businessname !== '' && 
      this.businesstype !== '' && 
      this.taxid !== '' && 
      this.taxpayertype.k !== '' && 
      this.status !== 'true') {
        this.invalid = false
      } else {
        this.invalid = true
      }

    },
    fmtUppercase(value) {
        let v = value.toUpperCase()
        v = v.length > 13 ? v.substr(0,13) : v
        return v
    },
    async register() {
      this.onRegisterBusy = true
      const success = await this.$refs.registerForm.validate();
        if (success) {
          try {
            const response = await axios
              .post('/ob/preflight', {email: this.businessemail, taxid: this.taxid} );
            if(response.data.check === 'taxid_and_email_available') {
                const auth = getAuth()
                try {
                  const createResponse = await createUserWithEmailAndPassword(auth, this.businessemail, this.password)
                  const tokenResponse = createResponse._tokenResponse

                  console.group('fg.core.register')
                  console.log('register.validate createUserWithEmailAndPassword', tokenResponse)
                  console.groupEnd()
                  let campaignResponse;

                  if(this.campaign_uuid){
                    try {
                      campaignResponse = await axios
                      .post('/c/get', { campaign_uuid: this.campaign_uuid } )
                      this.partner = campaignResponse.data.campaign.sponsor.alias;
                      if(!campaignResponse.data.campaign.available){
                        this.campaign_uuid = ""
                      }
                    } catch (error) {
                      console.log(error);
                      this.campaign_uuid = ""
                    }
                  }
                  
                  const requestObRegister = { businessname: this.businessname, businessregime: this.businessregime, email: this.businessemail, type: this.businesstype, taxpayertype: this.taxpayertype, taxid: this.taxid, partner: this.partner, token: tokenResponse }
                  if(this.campaign_uuid){
                    requestObRegister.campaign = campaignResponse.data
                  }
                  const registerResponse = await axios
                    .post('/ob/register',  requestObRegister)

                  try {
                    const { session, tokenBundle } = registerResponse.data
                    const textRoast = session.business.type === 'pf' ? 
                      `Te has registrado correctamente` :
                      `Te has registrado correctamente como ${session.account.name}.`

                    useJwt.setToken(session.accessToken)
                    useJwt.setRefreshToken(session.refreshToken)
                    localStorage.setItem('session', JSON.stringify(session))
                    localStorage.setItem('tokenBundle', JSON.stringify(tokenBundle))

                    if(this.campaign_uuid){
                      campaignResponse = await axios
                        .post('/c/assign', { campaign_uuid: this.campaign_uuid } )
                    }
                    
                    
                    this.onRegisterBusy = false
                    if(this.campaign_uuid){
                      const request = {
                        event: {
                          name: campaignResponse.data.campaign_uuid,
                          count: campaignResponse.data.reward.tokens,
                          meta: {
                            source: campaignResponse.data.sponsor.uuid,
                            target: tokenBundle.uuid,
                            cdate: new Date()
                          },
                        }
                      }
                      const tokenTransferResponse = await axios
                        .post('/mgmt/token/transfer', request )
                      if(tokenTransferResponse.data.uuid){
                        tokenBundle.count += campaignResponse.data.reward.tokens
                        localStorage.setItem('tokenBundle', JSON.stringify(tokenBundle))
                      }
                    }

                    this.$ability.update(session.account.ability)
                    this.$router.replace(getHomeRouteForLoggedInUser(session.account.role)).then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Bienvenido ${session.business.name}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: textRoast,
                        },
                      })
                    })
                  } catch (registerError) {
                    this.onRegisterBusy = false
                    console.log('register.error',registerError.code, registerError.message);
                    this.$refs.registerForm.setErrors({ email: [ registerError.code ] })
                  }
                } catch (createError) {
                  this.onRegisterBusy = false
                  console.log('createUserWithEmailAndPassword.error',createError.code, createError.message);
                  this.$refs.registerForm.setErrors({ email: [ createError.code ] })
                }
              } else {
                this.onRegisterBusy = false
                this.$refs.registerForm.setErrors({ email: ['Al parecer ya tienes una cuenta, intenta ingresar'] })

              }
          } catch (error) {
            this.onRegisterBusy = false
            this.$refs.registerForm.setErrors({ email: ['Correo o RFC registrado previamente'], taxid: ['RFC o correo registrado previamente'] })
          }
        }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
